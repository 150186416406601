<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/weapphome/category' }">品类管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{type === 'see' ? '查看品类' : type === 'edit' ? '编辑品类' : '添加品类'}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{type === 'see' ? '查看品类' : type === 'edit' ? '编辑品类' : '添加品类'}}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" :rules="type === 'see' ? {} : rules" ref="form"
                        :model="form" :label-width="this.env.label_width">
                        <el-form-item label="品类名称" prop="name">
                            <p v-if="type === 'see'">{{form.name }}</p>
                            <el-input v-else :value="form.name" placeholder="请输入品类名称" @input="getValue">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="品类类型" prop="type">
                            <section v-if="type ==='see'" style="display:flex">
                                <p>{{ form.type === 1 ? '运动品类' : form.type === 2 ? '目标品类' : form.type === 3 ? '心理需求品类' :
                                ''}}</p>
                                <p style="margin:0 4px" v-if="form.type === 1">- {{ form.level === 1 ? '一级品类' : '二级品类'}}
                                    <span v-if="form.level === 2"> - {{firstTagsName}}</span>
                                </p>
                            </section>
                            <el-cascader v-else v-model="value" :options="options" @change="handleChange"
                                style="width:380px"></el-cascader>
                        </el-form-item>
                        <!-- 如果是心理需求品类，所关联的运动品类 -->
                        <el-form-item v-if="form.type === 3" label="关联运动品类">
                            <el-select v-if="type !== 'see'" v-model="sportValue" filterable placeholder="请关联一级运动品类"
                                @change="handleMentalChange($event,'sport')" style="width:380px">
                                <el-option v-for="item in sportCategoryList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in chooseSportList" class="form-tag_colour-tag"
                                    effect="dark" :closable="type !== 'see'" @close="tagClose(item,  'sport')">
                                    {{ item.label }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <!-- 如果是心理需求品类，所关联的目标品类 -->
                        <el-form-item v-if="form.type === 3" label="关联目标品类">
                            <el-select v-if="type !== 'see'" v-model="targetValue" filterable placeholder="请关联目标品类"
                                @change="handleMentalChange($event,'target')" style="width:380px">
                                <el-option v-for="item in targetCategoryList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in chooseTargetList" class="form-tag_colour-tag"
                                    effect="dark" :closable="type !== 'see'" @close="tagClose(item, 'target')">
                                    {{ item.label }}
                                </el-tag>
                            </div>
                        </el-form-item>

                        <el-form-item v-if="form.type === 1 || form.type === 2" label="图标"
                            prop="logo">
                            <Qnupload v-model="form.logo" :sum="1" :isclipper="true" :fixedNumber="[66,66]"
                                :readonly="type === 'see'" />
                            <p v-if="type !== 'see'" style="color:#fc142f;margin:0">图片尺寸66px*66px</p>
                        </el-form-item>
                        <el-form-item v-if="form.type === 1 || form.type === 2" label="排序"
                            prop="sort">
                            <p v-if="type === 'see'">
                                {{form.sort}}
                            </p>
                            <section v-else>
                                <el-input-number :step="1" :min="0" v-model="form.sort" type="number">
                                </el-input-number>
                                <p style="color:#fc142f;margin:0">当排序序号相同时，最新保存的显示在前面</p>
                            </section>
                        </el-form-item>
                        <el-form-item label="品类描述">
                            <p v-if="type === 'see'">{{form.desc}}</p>
                            <el-input v-else v-model="form.desc" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                maxlength="2000" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="is_auth('categorytags.tags.savedata') && type !== 'see'" size="mini"
                                type="primary" @click="save">
                                {{ type === 'edit' ? '保存' : '添加'}}
                            </el-button>
                            <el-button size="mini" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>

        </div>
    </div>
</template>
<script>
// import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        // Wangeditor,
        Qnupload,
    },
    data() {
        return {
            select_tag: '',
            type: '', // 页面类型
            tag_list: [],
            loading: true,
            id: '',
            form: {
                id: 0,
                name: "",
                sort: 0,
                level: 0,
                type: 0,
                desc: "",
                logo: "",
                first_pids: 0,
                sport_pids: [],
                sport_target_pids: [],
            },
            firstTagsName: "",
            lesson_video_carousel: false,    // 视频是否加入轮播图
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 1, max: 10, message: '最大只允许输入10个字符', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择品类类型', trigger: 'blur' }
                ],
                logo: [
                    { required: true, message: '请选择一张logo', trigger: 'change' }
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' }
                ],
            },
            value: [],
            sportValue: [],
            targetValue: [],
            sportCategoryList: [], // 一级运动品类列表数据
            targetCategoryList: [], // 目标品类列表数据
            chooseSportList: [], // 当前选中的心理需求品类关联的运动品类数据
            chooseTargetList: [], // 当前选中的心理需求品类关联的目标品类数据
            options: [
                {
                    value: 1,
                    label: '运动品类',
                    children: [{
                        value: 1,
                        label: '一级品类',
                    }, {
                        value: 2,
                        label: '二级品类',
                        children: []
                    }]
                },
                {
                    value: 2,
                    label: '目标品类',
                },
                {
                    value: 3,
                    label: '心理需求品类',
                }
            ]
        }
    },
    // 创建
    created() {
        this.init()
    },
    methods: {
        getValue(e) {
            this.form.name = e.replace(/[^\u4E00-\u9FA5]/g, '');
        },
        handleChange(e) {
            this.form.type = e[0];
            if (e[0] !== 3) {
                this.form.sport_pids = [];
                this.form.sport_target_pids = [];
                this.sportValue = [];
                this.targetValue = [];
                this.chooseSportList = [];
                this.chooseTargetList = [];
            } else {
                this.form.logo = "";
                this.form.sort = 0;
            }
            if (e.length === 1) {
                this.form.level = 0;
                this.form.first_pids = 0;
            } else if (e.length === 2) {
                this.form.level = e[1];
                this.form.first_pids = 0
            } else if (e.length === 3) {
                this.form.level = e[1];
                this.form.first_pids = e[2];
                this.form.logo = "";
                this.form.sort = 0;
            }
        },
        handleMentalChange(id, type) {
            let { sportCategoryList, targetCategoryList, chooseSportList, chooseTargetList } = this;
            let itemJson = {};
            if (type === 'sport') {
                sportCategoryList.map(item => {
                    if (item.value === id) {
                        itemJson = item;
                    }
                })
                if (this.tag_exist(id, chooseSportList)) {
                    chooseSportList = this.chooseSportList.concat(itemJson);
                    this.chooseSportList = chooseSportList;
                    this.form.sport_pids = this.form.sport_pids.concat(id);
                }
                this.sportValue = "";
            } else if (type === 'target') {
                targetCategoryList.map(item => {
                    if (item.value === id) {
                        itemJson = item;
                    }
                })
                if (this.tag_exist(id, chooseTargetList)) {
                    chooseTargetList = this.chooseTargetList.concat(itemJson);
                    this.chooseTargetList = chooseTargetList;
                    this.form.sport_target_pids = this.form.sport_target_pids.concat(id);
                }
                this.targetValue = "";
            }
        },
        // 判断标签是否存在
        tag_exist(id, data) {
            if (data.length > 0) {
                for (let i of data) {
                    if (id == i.value) {
                        return false
                    }
                }
                return true
            } else {
                return true
            }
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        async init() {
            let type = this.$route.query.type;
            if (type) {
                this.type = type;
            }
            await this.getTag();
        },
        getTag() {
            let { options } = this;
            let id = this.$route.query.id;
            let postData = {
                api_name: "categorytags.tags.gettagsaggregation",
                token: this.Tool.get_l_cache('token'),
            };
            this.Tool.post_data('oss', postData, (json) => {
                if (json.code === 0) {
                    for (let i in json.data.tag_types) {
                        if (json.data.tag_types[i].type === 1) {
                            this.sportCategoryList = this.sportCategoryList.concat({
                                value: json.data.tag_types[i].id,
                                label: json.data.tag_types[i].name,
                            });
                        } else if (json.data.tag_types[i].type === 2) {
                            this.targetCategoryList = this.targetCategoryList.concat({
                                value: json.data.tag_types[i].id,
                                label: json.data.tag_types[i].name,
                            });
                        }
                    }
                    options[0].children[1].children = this.sportCategoryList;
                    this.options = options;
                    if (!this.Tool.is_empty(id)) {
                        this.id = id
                        this.getinfo(id)
                    } else {
                        this.loading = false
                    }
                } else if (json.code === 10012) {
                    this.loading = false;
                    this.$message.error("无品类下拉框标签权限");
                }
            })
        },
        // 获取品类详情
        getinfo() {
            const { form, sportCategoryList, targetCategoryList } = this;
            let postdata = {
                api_name: "categorytags.tags.getinfo",
                token: this.Tool.get_l_cache('token'),
                id: this.id
            }
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    const { sport_pids, sport_target_pids, first_pids } = json.data.releation_ids;
                    for (let i in form) {
                        for (let j in json.data) {
                            if (i === j) {
                                form[i] = json.data[j];
                            }
                        }
                    }
                    // 设置品类类型
                    if (json.data.type === 1) { // 如果是运动品类
                        // 如果是一级品类
                        if (json.data.level === 1) {
                            this.value = [json.data.type, json.data.level];
                            // 如果是二级品类
                        } else {
                            this.value = [json.data.type, json.data.level, json.data.releation_ids.first_pids];
                            sportCategoryList.map(item => {
                                if (item.value === first_pids) {
                                    this.firstTagsName = item.label;
                                }
                            })
                        }
                    } else {
                        this.value = [json.data.type];
                    }
                    form.sport_pids = sport_pids ? sport_pids : [];
                    form.sport_target_pids = sport_target_pids ? sport_target_pids : [];
                    form.first_pids = first_pids ? first_pids : 0;
                    this.form = form;
                    if (json.data.type === 3) {
                        if (sport_pids) {
                            for (let i in sportCategoryList) {
                                sport_pids.map(item => {
                                    if (item === sportCategoryList[i].value) {
                                        this.chooseSportList = this.chooseSportList.concat(sportCategoryList[i]);
                                    }
                                })
                            }
                        }
                        if (sport_target_pids) {
                            for (let i in targetCategoryList) {
                                sport_target_pids.map(item => {
                                    if (item === targetCategoryList[i].value) {
                                        this.chooseTargetList = this.chooseTargetList.concat(targetCategoryList[i]);
                                    }
                                })
                            }
                        }
                    }
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        //保存
        save() {
            const { form } = this;
            let postdata = {
                api_name: "categorytags.tags.savedata",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, form);
            const checkResult = this.returnParamsIsEmptu(postdata);
            if (checkResult) {
                this.loading = true
                this.Tool.post_data('oss', postdata, (json) => {
                    this.loading = false
                    if (json.code === 0) {
                        this.$message({
                            message: '操作成功', type: 'success',
                            duration: this.env.message_duration,
                            onClose: () => {
                                this.isreturn();
                            }
                        });
                    } else {
                        this.Tool.errormes(json)
                    }
                })
            } else {
                this.$message.warning("请完善品类数据");
            }
        },
        returnParamsIsEmptu(postdata) {
            const { type, level, first_pids, name, logo } = postdata;
            let checkResult = true;
            if (!name) {
                checkResult = false
            } else {
                switch (type) {
                    // 如果是运动品类
                    case 1:
                        if (level === 1) {
                            if (!logo) {
                                checkResult = false;
                            }
                        } else {
                            if (first_pids === 0) checkResult = false;
                        }
                        break;
                    // 如果是目标品类
                    case 2:
                        if (!logo) {
                            checkResult = false;
                        }
                        break;
                    default:
                        checkResult = true;
                        break;
                }
            }
            return checkResult;
        },
        // 删除选中的关联品类
        tagClose(item, type) {
            if (type === 'sport') {
                this.chooseSportList.splice(this.chooseSportList.indexOf(item), 1);
                this.form.sport_pids.splice(this.form.sport_pids.indexOf(item.label), 1);
            } else {
                this.chooseTargetList.splice(this.chooseTargetList.indexOf(item), 1);
                this.form.sport_target_pids.splice(this.form.sport_target_pids.indexOf(item.label), 1);
            }
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
    margin: 0;
}
</style>
